<template>
  <div v-if="loading">
    <bb-loader />
  </div>
  <table
    v-else
    aria-describedby="Billing profiles added to account"
    class="w-full table-fixed"
  >
    <thead>
      <tr class="bg-bb-light-grey">
        <th scope="col">Store</th>
        <th scope="col">Subscription</th>
        <th colspan="2">Status</th>
        <th scope="col">Billing profile</th>
        <th scope="col">Primary payment</th>
      </tr>
    </thead>
    <tbody>
      <billing-form-row
        class="even:bg-bb-light-grey"
        v-for="store in stores"
        :key="store.id"
        :store="store"
      />
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConnectStoreModal from '@/components/modals/ConnectStoreModal'
import BbLoader from '@/components/loader/BBLoader'
import BillingFormRow from '@/components/billing/BillingFormRow.vue'

export default {
  name: 'account-billing-overview',
  components: {
    BillingFormRow,
    BbLoader,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters({
      stores: 'store/stores',
      loadingStores: 'store/isFetchingStores',
      loadingSubscriptions: 'subscription/isSubscriptionsLoading',
      loadingCards: 'card/isCardsLoading',
    }),
    loading() {
      return this.loadingStores || this.loadingSubscriptions || this.loadingCards || !this.loaded
    },
  },
  methods: {
    ...mapActions({
      loadSubscriptionsIfNeeded: 'subscription/loadSubscriptionsIfNeeded',
    }),
    connectStore() {
      this.$modal.show(ConnectStoreModal, null, ConnectStoreModal.modalProps)
    },
  },
  beforeMount() {
    this.loadSubscriptionsIfNeeded().then(() => (this.loaded = true))
  },
}
</script>

<style lang="scss" scoped>
th {
  text-align: start;
}

th,
td {
  @apply py-4 pl-2 border border-bb-pale-grey;
}
</style>
