<template>
  <div
    v-if="store.subscription_slug === 'free'"
    class="flex gap-2 items-center"
  >
    <dot success />
    Active Free
  </div>

  <div v-else-if="store.subscription_slug === 'pro'">
    <div
      v-if="downgradePending"
      class="flex gap-2 items-center"
    >
      <dot success />
      Downgrade to FREE on {{ downgradeDate }}
    </div>
    <div
      v-else-if="trialDaysLeft > 0"
      class="flex gap-2 items-center"
    >
      <dot success />
      Active, {{ trialDaysLeft }} days left of free trial
    </div>
    <div
      v-else
      class="flex gap-2 items-center"
    >
      <dot success />
      Active
    </div>
  </div>

  <div v-else-if="store.subscription_slug === 'pro_plus'">
    <div
      v-if="downgradePending"
      class="flex gap-2 items-center"
    >
      <dot success />
      Downgrade to FREE on {{ downgradeDate }}
    </div>
    <div
      v-else-if="store.live"
      class="flex gap-2 items-center"
    >
      <dot warn />
      Setup bidding in progress
    </div>
    <div
      v-else-if="trialDaysLeft > 0"
      class="flex gap-2 items-center"
    >
      <dot success />
      Active, {{ trialDaysLeft }} days left of free trial
    </div>
    <div
      v-else
      class="flex gap-2 items-center"
    >
      <dot success />
      Active
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Dot from '@/components/shared/Dot'
import { mapGetters } from 'vuex'

export default {
  name: 'subscription-status',
  components: { Dot },

  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      trialDaysLeft: 'store/trialDaysLeft',
    }),
    downgradePending() {
      return this.store.states.downgrade.status
    },
    downgradeDate() {
      return dayjs(this.store.states.downgrade.expire).format('YY-MM-DD')
    },
  },
}
</script>
