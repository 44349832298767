<template>
  <base-picker
    :label="label"
    :placeholder="placeholder || 'Select billing profile'"
    :value="value"
    @input="val => $emit('input', val)"
    @blur="$emit('blur')"
    :items="billings"
    :item-label="item => item.company_name"
    :item-value="item => item.id"
    :loading="loading"
    :success="success"
    :disabled="disabled"
    :error="error"
    :error-message="errorMessage"
    :input-name="inputName"
    :input-id="inputId"
    :hide-feedback="hideFeedback"
  />
</template>

<script>
import BasePicker from '@/components/input/base/BasePicker'

export default {
  name: 'billing-picker',
  components: { BasePicker },
  props: {
    value: {},
    billings: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    success: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'blur'],
}
</script>
