<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <bb-icon
            icon="ic-receipt"
            :size="32"
          />
        </template>
        <template #default> Billing overview </template>
      </page-title>
    </page-section>

    <page-section bottom-border>
      <account-billing-overview />
    </page-section>
  </page>
</template>

<script>
import AccountBillingOverview from '@/components/account/AccountBillingOverview'
import PageSection from '@/components/base/page/PageSection.vue'
import Page from '@/components/base/page/Page.vue'

export default {
  name: 'Overview',
  components: { Page, PageSection, AccountBillingOverview },
}
</script>

<style scoped></style>
