<template>
  <tr v-if="loading">
    <td colspan="6">
      <bb-loader />
    </td>
  </tr>
  <tr v-else>
    <td class="truncate">
      {{ store.name }}
    </td>
    <td class="my-auto">
      <div class="flex items-center">
        <subscription-badge :slug="store.subscription_slug" />
      </div>
    </td>
    <td colspan="2">
      <subscription-status :store="store" />
    </td>
    <td class="pt-2 pr-2">
      <div v-if="!loadingBillings && !billings">No billing profile</div>
      <billing-picker
        v-if="!loadingBillings"
        @input="billingId => updateBilling(store.id, billingId)"
        v-model="input.billing"
        :disabled="updating"
        :billings="billings"
        :input-id="instanceId('billing')"
        input-name="billing"
        hide-feedback
      />
    </td>
    <td class="pt-2 pr-2">
      <div v-if="!loadingCards && cards.length < 0">No card connected</div>
      <payment-picker
        v-else
        @input="paymentId => updatePayment(store.id, paymentId)"
        v-model="input.payment"
        :disabled="updating"
        :options="paymentOptions"
        :input-id="instanceId('payment')"
        input-name="payment"
        hide-feedback
      />
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SubscriptionBadge from '@/components/subscriptions/SubscriptionBadge'
import BbLoader from '@/components/loader/BBLoader'
import SubscriptionSelectionModal from '@/components/modals/SubscriptionSelectionModal'
import SubscriptionStatus from '@/components/subscriptions/SubscriptionStatus'
import BillingPicker from '@/components/input/BillingPicker'
import PaymentPicker from '@/components/input/PaymentPicker'
import ActionButton from '@/components/btn/ActionButton'
import Toast from '@/components/shared/Toast'

const METHOD_ID = {
  CARD: 1,
  INVOICE: 2,
}

const PAYMENT_INVOICING = {
  method_type: 'INVOICE',
  method_type_id: METHOD_ID.INVOICE,
  external_id: null,
  name: 'INVOICE',
  id: 'invoice',
}

const DELETED_CARD = {
  id: 0,
  name: 'DELETED',
}

export default {
  name: 'billing-form-row',
  components: {
    ActionButton,
    SubscriptionStatus,
    BbLoader,
    SubscriptionBadge,
    BillingPicker,
    PaymentPicker,
    Toast,
  },
  props: {
    store: {
      type: Object,
    },
  },
  data() {
    return {
      loaded: false,
      updating: false,
      input: {
        payment: null,
        billing: null,
      },
    }
  },
  watch: {
    updateError(error) {
      if (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Could not update billing',
            type: 'error',
          },
        })
      }
    },
    store(_) {
      this.populateFields()
    },
  },
  computed: {
    ...mapGetters({
      loadingStores: 'store/isFetchingStores',
      updateStoreErrorById: 'store/getUpdateStoreErrorById',
      loadingSubscriptions: 'subscription/isSubscriptionsLoading',
      cards: 'card/getCards',
      cardById: 'card/getCardByStripeId',
      loadingCards: 'card/isCardsLoading',
      billings: 'billing/getAccountBillings',
      loadingBillings: 'billing/isLoadingAccountBillings',
      account: 'account/getAccount',
    }),
    loading() {
      return this.loadingStores || this.loadingSubscriptions || this.loadingCards || !this.loaded
    },
    updateError() {
      return this.updateStoreErrorById(this.store.id)
    },
    paymentOptions() {
      if (this.account.enterprise) return [PAYMENT_INVOICING, ...this.cards.map(this.cardToPaymentOption)]
      return [...this.cards.map(this.cardToPaymentOption)]
    },
  },
  methods: {
    ...mapActions({
      loadSubscriptionsIfNeeded: 'subscription/loadSubscriptionsIfNeeded',
      updateStore: 'store/updateStore',
    }),
    updatePayment(storeId, payment) {
      this.updating = true

      this.updateStore({
        storeId,
        data: {
          payment_method_type_id: payment.method_type_id,
          payment_method_external_id: payment.external_id,
        },
      }).finally(() => (this.updating = false))
    },
    updateBilling(storeId, billingId) {
      this.updating = true
      this.updateStore({ storeId, data: { billing_id: billingId } }).finally(() => (this.updating = false))
    },
    instanceId(uniqueName) {
      return `${this._uid}:${uniqueName}`
    },
    populateFields() {
      this.input.payment = null
      this.input.billing = null
      if (!this.store) {
        return
      }

      this.input.billing = this.store.billing_id

      const invoiceSelected = this.store.payment_method_type_id === METHOD_ID.INVOICE
      if (invoiceSelected && this.account.enterprise) {
        this.input.payment = PAYMENT_INVOICING
      } else {
        const card = this.cardById(this.store.payment_method_external_id)
        this.input.payment =
          this.store.payment_method_external_id && !card
            ? this.cardToPaymentOption(DELETED_CARD)
            : this.cardToPaymentOption(card)
      }
    },
    cardToPaymentOption(card) {
      if (!card) return null
      return {
        method_type: 'card',
        method_type_id: METHOD_ID.CARD,
        external_id: card.id,
        ...card,
      }
    },
  },
  beforeMount() {
    this.populateFields()
    this.loadSubscriptionsIfNeeded().then(() => (this.loaded = true))
  },
}
</script>

<style lang="scss" scoped>
th,
td {
  @apply py-4 pl-2 border border-bb-pale-grey;
}
</style>
