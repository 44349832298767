<template>
  <div
    class="dot"
    :style="cssVars"
    :class="{ success, warn, error }"
  ></div>
</template>

<script>
export default {
  name: 'dot',
  props: {
    success: Boolean,
    warn: Boolean,
    error: Boolean,
    size: {
      type: String,
      default: '10',
    },
  },
  computed: {
    cssVars() {
      return {
        '--size': this.size.concat('px'),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dot {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  @apply bg-bb-cool-grey;

  &.success {
    @apply bg-bb-dull-green;
  }

  &.warn {
    @apply bg-bb-dull-orange;
  }

  &.error {
    @apply bg-bb-dull-red;
  }
}
</style>
